<template>
  <div>
    <div class="p-2 px-4 bg-white border border-black relative faq-wrapper">
      <details class="cursor-pointer user-select-none details-open">
        <summary class="flex items-center justify-center h-full">
          <span class="flex-1 uppercase leading-4 user-select-none text-blue-700">{{ title }}</span>
          <svg
            class="transition-all ease-in-out duration-300 text-blue-700"
            width="41"
            height="41"
            viewBox="0 0 41 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.3541 16.3639L20.8207 22.8306L27.2874 16.3639C27.4417 16.2096 27.6249 16.0872 27.8265 16.0037C28.0281 15.9202 28.2442 15.8772 28.4624 15.8772C28.6806 15.8772 28.8967 15.9202 29.0983 16.0037C29.2999 16.0872 29.4831 16.2096 29.6374 16.3639C29.7917 16.5182 29.9141 16.7014 29.9976 16.903C30.0811 17.1046 30.1241 17.3207 30.1241 17.5389C30.1241 17.7571 30.0811 17.9732 29.9976 18.1748C29.9141 18.3764 29.7917 18.5596 29.6374 18.7139L21.9874 26.3639C21.8332 26.5184 21.6501 26.641 21.4484 26.7246C21.2468 26.8083 21.0307 26.8513 20.8124 26.8513C20.5941 26.8513 20.378 26.8083 20.1764 26.7246C19.9747 26.641 19.7916 26.5184 19.6374 26.3639L11.9874 18.7139C11.8329 18.5597 11.7103 18.3766 11.6267 18.1749C11.543 17.9733 11.5 17.7572 11.5 17.5389C11.5 17.3206 11.543 17.1045 11.6267 16.9029C11.7103 16.7012 11.8329 16.5181 11.9874 16.3639C12.6374 15.7306 13.7041 15.7139 14.3541 16.3639Z"
              fill="currentColor"
            />
          </svg>
        </summary>
        <div class="cursor-default pb-2 pt-1 text-sm space-y-4" v-html="content">
          
        </div>
      </details>
      <div
        data-v-54cdc180=""
        class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FAQItem",
  props: ["title", "content"],
};
</script>

<style lang="scss">

.faq-wrapper {
  min-height: 66px;
}
details {
  height: 48px;
}

summary::-webkit-details-marker {
  display:none;
}

details[open] {
  height: auto;
  svg {
    transform: rotate(180deg);
  }
}

.user-select-none {
  user-select: none;
}
</style>
